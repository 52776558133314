import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

const Form = ({
  onFormChange,
  onFormValidate,
  onSubmit,
  children,
  ...props
}) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [forceValidation, setForceValidation] = useState(false); // eslint-disable-line no-unused-vars

  const formRef = useRef();

  const getFormInputs = () => {
    const inputs = [];

    if (!formRef.current) {
      return inputs;
    }

    const inputQuery = formRef.current.querySelectorAll(
      'input:not([no-data]), select:not([no-data]), textarea:not([no-data])'
    );

    for (let i = 0, l = inputQuery.length; i < l; i++) {
      inputs.push(inputQuery[i]);
    }

    return inputs;
  };

  const markFieldsTouched = (fields) => {
    fields.forEach((f) => {
      f.touched = true;
    });
  };

  const getFieldValue = (field) => {
    if (field.type === 'checkbox') {
      return field.checked;
    }
    if (field.multiple) {
      return Array.from(field.selectedOptions).map((o) => o.value);
    }

    return field.value;
  };

  const buildFormData = () => {
    const formInputs = getFormInputs();
    const formData = {};

    formInputs.forEach((f) => {
      if (f.type === 'radio') {
        if (f.checked) {
          formData[f.name || f.id] = f.value;
        }
      } else {
        let key = f.name || f.id;
        if (key) {
          formData[key] = getFieldValue(f);
        }
      }
    });

    return formData;
  };

  const buildFormState = () => {
    const formInputs = getFormInputs();
    const formState = {};

    formInputs.forEach((f) => {
      let key = f.name || f.id;
      if (key) {
        formState[key] = { value: getFieldValue(f), touched: f.touched };
      }
    });

    return formState;
  };

  const handleKeyDown = (ev) => {
    // Tab key = '9'
    if (ev.keyCode === 9) {
      setForceValidation(true);
    }
  };

  const attachRealTimeValidation = () => {
    if (formRef && formRef.current) {
      const formInputs = getFormInputs();
      formInputs.forEach((f) => {
        if (f.type === 'checkbox') {
          f.removeEventListener('change', fieldValidation); // prevent duplicate binding
          f.addEventListener('change', fieldValidation);
        } else {
          f.removeEventListener('blur', fieldValidation); // prevent duplicate binding
          f.addEventListener('blur', fieldValidation);
        }
      });
    }
  };

  const fieldValidation = (ev) => {
    markFieldsTouched([ev.target]);

    if (onFormChange) {
      const formData = buildFormData();
      onFormChange(formData);
    }

    if (onFormValidate) {
      const formState = buildFormState();
      onFormValidate(formState);
    }

    setForceValidation(false);
  };

  const handleSubmit = (ev) => {
    ev.preventDefault();

    markFieldsTouched(getFormInputs());

    if (onFormValidate) {
      const formState = buildFormState();
      const hasErrors = onFormValidate(formState);

      if (!hasErrors) {
        performSubmit();
      }
    } else {
      performSubmit();
    }
  };

  const performSubmit = () => {
    if (onSubmit) {
      const formData = buildFormData();
      onSubmit(formData);
    }
  };

  useEffect(() => {
    attachRealTimeValidation();

    if (onFormChange) {
      const formData = buildFormData();
      onFormChange(formData);
    }

    return () => {
      const formInputs = getFormInputs();
      formInputs.forEach((f) => {
        if (f.type === 'checkbox') {
          f.removeEventListener('change', fieldValidation);
        } else {
          f.removeEventListener('blur', fieldValidation);
        }
      });
    };
  }, []);

  return (
    <form
      {...props}
      noValidate
      onKeyDown={handleKeyDown}
      ref={formRef}
      onSubmit={handleSubmit}
    >
      {children}
    </form>
  );
};

Form.propTypes = {
  onSubmit: PropTypes.func,
  onFormValidate: PropTypes.func,
  onFormChange: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default Form;
