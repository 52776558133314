import React from 'react';
import PropTypes from 'prop-types';
import Link from 'next/link';
import { isUrlInternal, isUrlSameDomain } from '@utils/utils';

/*
  Internal links will open within the same single-page-application (SPA).
  Otherwise, a normal anchor link is added, with external domains opening in a new window.

  className = May also include reference(s) for google tag manager.
  dataTestId = For testing with jest.
  onClick  = May include a pushGTMEvent.
*/

const LinkWrapper = ({ className, dataTestId, url, onClick, children }) => {
  return isUrlInternal(url) ? (
    <Link
      href={url}
      className={className}
      data-testid={dataTestId}
      onClick={onClick}
    >
      {children}
    </Link>
  ) : (
    <a
      href={url}
      className={className}
      data-testid={dataTestId}
      onClick={onClick}
      target={isUrlSameDomain(url) ? '_self' : '_blank'}
      rel="noreferrer"
    >
      {children}
    </a>
  );
};

export default LinkWrapper;

LinkWrapper.propTypes = {
  className: PropTypes.string,
  dataTestId: PropTypes.string,
  url: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.any,
};
