import styled from 'styled-components';
import Colors from 'pubweb-smokey/dist/colors';

const TextFieldStyles = styled.div`
  @keyframes label-fade-transition {
    0% {
      font-size: 14px;
      left: 0;
      opacity: 1;
      top: 13px;
    }

    22% {
      left: 0;
    }

    45% {
      color: ${Colors.primary.claytonBlue.standard};
      font-size: 14px;
      left: -25%;
      opacity: 0;
      top: 13px;
    }
    55% {
      color: ${Colors.primary.claytonBlue.standard};
      font-size: 11px;
      left: -25%;
      opacity: 0;
      top: -5px;
    }
    77% {
      left: -25%;
    }
    100% {
      color: ${Colors.primary.claytonBlue.standard};
      font-size: 11px;
      left: 0;
      opacity: 1;
      top: -5px;
    }
  }

  @keyframes label-fade-transition-outline {
    0% {
      font-size: 14px;
      left: 0;
      opacity: 1;
      top: 13px;
    }

    22% {
      left: 0;
    }

    45% {
      font-size: 14px;
      left: -25%;
      opacity: 0;
      top: 13px;
    }
    55% {
      color: ${Colors.primary.claytonBlue.standard};
      font-size: 11px;
      left: -25%;
      opacity: 0;
      top: 1px;
    }
    77% {
      left: -25%;
    }
    100% {
      font-size: 11px;
      left: 0;
      opacity: 1;
      top: 1px;
    }
  }

  font-family: 'source-sans-pro', sans-serif;

  label {
    color: ${Colors.accent.grey1.standard};
    display: block;
    font-size: 15px;
    line-height: 25px;
    pointer-events: none;
  }
  input {
    background-color: #fff;
    border: 0;
    border-bottom: solid 1px ${Colors.accent.grey2.standard};
    box-sizing: border-box;
    color: ${Colors.primary.black.standard};
    font-family: 'source-sans-pro', sans-serif;
    font-size: 16px;
    height: 42px;
    line-height: 1.56;
    letter-spacing: -0.3px;
    padding: 9px 33px 8px 13px;
    text-align: left;
    width: 100%;
  }
  input:active,
  input:focus {
    border-color: ${Colors.primary.claytonBlue.standard};
    outline: none;
  }

  input:disabled {
    border-color: ${Colors.accent.grey3.standard};
    cursor: not-allowed;
    color: ${Colors.accent.grey3.standard};
  }
  .error-message {
    color: ${Colors.accent.ladyBug.highContrast};
    font-size: 12px;
    height: 22px;
    letter-spacing: -0.2px;
    line-height: 1.83;
    margin-top: 0;
    text-align: left;
  }

  &.animated {
    min-height: 72px;
    position: relative;
    overflow-x: hidden;

    input:focus {
      border-color: ${Colors.accent.grey1.standard};
    }

    label {
      color: ${Colors.accent.grey1.standard};
      font-size: 16px;
      padding-left: 1px;
      position: absolute;
      opacity: 1;
      top: 13px;
    }

    input {
      border-left-color: transparent;
      border-right-color: transparent;
      border-top-color: transparent;
      padding: 0;
      padding-top: 10px;
      width: 100%;

      &:focus {
        & ~ .focus-bar {
          width: 100%;
        }
      }
    }

    .focus-bar {
      border-top: 1px solid ${Colors.primary.claytonBlue.standard};
      display: block;
      position: absolute;
      top: 41px;
      transition: width 0.25s ease-in;
      width: 0;
    }

    .error-message {
      height: auto;
      padding-left: 1px;
    }

    input:focus + label,
    input:valid + label,
    input.valid + label {
      animation: label-fade-transition 0.3s linear;
      font-size: 11px;
      display: block;
      top: -5px;
    }

    input:focus + label {
      color: ${Colors.primary.claytonBlue.standard};
    }
  }

  &.animated.has-background {
    input {
      background-color: ${Colors.secondary.grey6.standard};
      padding-bottom: 20px;
      padding-left: 10px;
      padding-top: 26px;
    }

    .focus-bar {
      top: 46px;
    }

    label {
      left: 8px;
    }
    .error-message {
      line-height: 1.42;
      margin-bottom: 5px;
      padding-left: 10px;
    }
  }

  &.compact {
    input {
      border: solid 1px ${Colors.accent.grey2.standard};
      border-radius: 2px;

      &:focus {
        border-color: ${Colors.primary.claytonBlue.standard};
      }
    }
    &.disabled input {
      border-color: ${Colors.accent.grey3.standard};
    }
    .error-message {
      padding-left: 12px;
    }
    &.error {
      input {
        border-color: ${Colors.accent.ladyBug.standard};
      }
    }
  }

  &.outlined {
    input {
      border: 1px solid ${Colors.accent.grey2.standard};
      border-radius: 2px;
      height: 54px;
      padding-left: 12px;
      padding-top: 0;
    }
    .error-message {
      padding-left: 10px;
    }

    &.error {
      input {
        border-color: ${Colors.accent.ladyBug.standard};
      }
    }

    &.disabled input {
      border-color: ${Colors.accent.grey3.standard};
    }
    .focus-bar {
      display: none;
    }
    &.animated label {
      padding-left: 12px;
      top: 15px;
    }

    input:focus {
      border-color: ${Colors.primary.claytonBlue.standard};
    }

    input:focus,
    input:valid,
    input.valid {
      & + label {
        animation: label-fade-transition-outline 0.3s linear;
        top: 1px;
      }
    }
  }

  input.valid:disabled + label {
    color: ${Colors.accent.grey3.standard};
  }

  &.error {
    .focus-bar,
    input,
    input:disabled {
      border-color: ${Colors.accent.ladyBug.standard};
    }

    input:focus {
      border-color: ${Colors.accent.ladyBug.standard};
      & + label {
        color: ${Colors.accent.ladyBug.highContrast};
      }
    }

    input + label,
    input:valid + label,
    input.valid + label {
      color: ${Colors.accent.ladyBug.highContrast};
    }

    .focus-bar {
      width: 100%;
    }
  }
`;

export default TextFieldStyles;
