import xHttp from '@utils/xHttp';
import {
  getSafeUrl,
  getUTMParametersFromSessionCache,
} from 'pubweb-smokey/dist/utils/utils';
import { getBaseApiUrl } from '@utils/config';
import { getGAClientId, getKenshooId } from '@utils/utils';

//This may need to be edited to full work with Sweet 16. Most of this was pieced together from an example in landing pages.
export const submitForm = (formObj) => {
  const url = `${getBaseApiUrl()}leads`;

  const utmParams = getUTMParametersFromSessionCache();

  const formModel = {
    emailAddress: formObj.email ? formObj.email.trim() : undefined,
    firstName: formObj.firstName ? formObj.firstName.trim() : undefined,
    lastName: formObj.lastName ? formObj.lastName.trim() : undefined,
    zip: formObj.zip ? formObj.zip.trim() : undefined,
    phoneNumber: formObj.phone ? formObj.phone.trim() : undefined,
    canEmail: formObj.canEmail,
    dataExtension: formObj.dataExtension,
    doNotCall: formObj.doNotCall,
    writtenTextMessageConsent:
      formObj.writtenTextMessageConsent === true ? true : null,
    originWebURL: getSafeUrl(),
    originWebApp: formObj.originWebApp,
    originWebPageType: formObj.originWebPageType,
    originWebFormType: formObj.originWebFormType,
    originWebFormCategory: formObj.originWebFormCategory,
    originCampaign: formObj.originCampaign,
    dealerNumber: formObj.dealerNumber
      ? formObj.dealerNumber.trim()
      : undefined,
    modelNumber: formObj.modelNumber ? formObj.modelNumber.trim() : undefined,
    leadComments: formObj.comments ? formObj.comments.trim() : undefined,
    pubWebLeadId: formObj.pubWebLeadId ? formObj.pubWebLeadId : undefined,
    gaClientId: getGAClientId(),
    kenshooId: getKenshooId(),
    utmCampaign: utmParams.utm_campaign,
    utmSource: utmParams.utm_source,
    utmMedium: utmParams.utm_medium,
    utmTerm: utmParams.utm_term,
    utmContent: utmParams.utm_content,
    googleClickId: utmParams.gclid,
    bingClickId: utmParams.msclkid,
    facebookClickId: utmParams.fbclid,
  };

  return xHttp.post(url, formModel);
};

export const submitSupplementalForm = (formObj) => {
  const url = `${getBaseApiUrl()}leads/supplemental`;

  const formModel = {
    planToBuyMonth: formObj.moveInMonth ? formObj.moveInMonth : undefined,
    planToBuyYear: formObj.moveInYear ? formObj.moveInYear : undefined,
    priceRange: formObj.budget ? formObj.budget.toString() : undefined,
    cashSale: formObj.cashSale === 'Need Financing' ? 'No' : 'Yes',
    land: formObj.land === 'I Do Not Have Land' ? 'Needs Land' : 'Has Land',
    pubWebLeadId: formObj.pubWebLeadId ? formObj.pubWebLeadId : undefined,
  };

  return xHttp.post(url, formModel);
};

export const submitCATForm = (formObj) => {
  const url = `${getBaseApiUrl()}leads/cat`;

  const data = {
    firstName: formObj.firstName ? formObj.firstName.trim() : undefined,
    lastName: formObj.lastName ? formObj.lastName.trim() : undefined,
    phone: formObj.phone ? formObj.phone.trim() : undefined,
    email: formObj.email ? formObj.email.trim() : undefined,
    preferredContactMethod: formObj.preferredContactMethod,
    serialNumber: formObj.serial ? formObj.serial.trim() : undefined,
    purchaseLocation: formObj.location ? formObj.location.trim() : undefined,
    yearPurchased: formObj.year ? parseInt(formObj.year) : undefined,
    comments: formObj.comments ? formObj.comments.trim() : undefined,
    gaClientId: getGAClientId(),
  };

  return xHttp.post(url, data);
};

// form submission for CCPA privacy policies
export const submitCcpaCATForm = (formData) => {
  const url = `${getBaseApiUrl()}leads/ccpa`;

  const data = {
    firstName: formData.firstName ? formData.firstName.trim() : undefined,
    lastName: formData.lastName ? formData.lastName.trim() : undefined,
    phone: formData.phone ? formData.phone.trim() : undefined,
    email: formData.email ? formData.email.trim() : undefined,
    knowAccess: formData.knowAccess,
    categories: formData.categories,
    disclosure: formData.disclosure,
    deletion: formData.deletion,
    correction: formData.correction,
    appeal: formData.appeal,
    customer: formData.customer,
    businessContact: formData.businessContact,
    currentEmployee: formData.currentEmployee,
    formerEmployee: formData.formerEmployee,
    applicant: formData.applicant,
    authorizedAgent: formData.authorizedAgent,
    preferredContactMethod: formData.preferredContactMethod,
    source: formData.source,
    userId: formData.userId,
    googleAnalyticsId: getGAClientId(),
    streetAddress: formData.address ? formData.address.trim() : undefined,
    city: formData.city ? formData.city.trim() : undefined,
    state: formData.state ? formData.state.trim() : undefined,
    zipCode: formData.zip ? formData.zip.trim() : undefined,
  };

  return xHttp.post(url, data);
};
