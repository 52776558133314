import styled from 'styled-components';

const HomesCarouselStyles = styled.div`
  .mobile-single-card {
    width: 86%;
    margin: auto;
  }

  .carousel-heading {
    padding-left: 1rem;
    padding-right: 1rem;
    margin-bottom: 1rem;
  }

  .carousel {
    position: relative;
  }

  // The img needs pointer events or you will not see the "open image in new tab" and other image related right-click menu options.
  .carousel {
    img {
      pointer-events: all;
      transform: translate(0%, 0%);
    }
  }

  .carousel__slide {
    text-align: left;
    padding-left: 4px;
    padding-right: 4px;
  }

  .direction-button {
    display: none;
    @media only screen and (min-width: 1024px) {
      display: inline;
    }
    position: absolute;

    transform: translateY(-50%);

    background-color: transparent;
    border-color: transparent;

    @media (min-width: 1024px) {
      top: 40%;
    }
    @media (min-width: 1280px) {
      top: 43%;
    }
  }

  .direction-button:disabled {
    display: none;
  }

  .direction-left {
    left: -60px;
    path {
      transform: translate(5px, 3px);
    }
  }

  .direction-right {
    right: -60px;
    path {
      transform: translate(5px, 3px);
    }
  }

  @media only screen and (min-width: 425px) {
    .carousel__slide {
      padding-left: 16px;
      padding-right: 16px;
    }
  }
`;

export default HomesCarouselStyles;
