import React, { useEffect } from 'react';
import TextFieldStyles from './TextField.styled';
import PropTypes from 'prop-types';

const TextField = React.forwardRef(function TextField(
  {
    automationId,
    className,
    disabled,
    defaultValue,
    errorMessage,
    id,
    name = null,
    textFieldStyle = 'primary',
    label,
    IS_PASSWORD = false,
    onChange = () => {},
    ...props
  },
  ref
) {
  const classes = [className];

  useEffect(() => {
    if (!automationId) {
      console.warn('Automation id was not provided.');
    }
  }, [automationId]);

  if (textFieldStyle === 'primary-background') {
    classes.push('has-background');
    textFieldStyle = 'primary';
  }

  if (textFieldStyle === 'outlined') {
    classes.push('outlined');
    textFieldStyle = 'primary';
  }

  if (textFieldStyle === 'primary') {
    classes.push('animated');
  }

  if (textFieldStyle === 'compact') {
    classes.push('compact');
  }

  if (errorMessage) {
    classes.push('error');
  }

  if (disabled) {
    classes.push('disabled');
  }

  if (defaultValue || (props.value && disabled)) {
    classes.push('valid');
  }

  return (
    <TextFieldStyles className={classes.join(' ')}>
      <input
        {...props}
        className={disabled && (defaultValue || props.value) ? 'valid' : ''}
        required={textFieldStyle === 'primary'}
        defaultValue={defaultValue}
        type={IS_PASSWORD === true ? 'password' : 'text'}
        onChange={onChange}
        id={id}
        name={name || id}
        disabled={disabled}
        placeholder={
          textFieldStyle.toLowerCase().trim() === 'compact' ? label : null
        }
        key={id || ''}
        ref={ref}
      />

      {textFieldStyle === 'primary' ? (
        <>
          <label htmlFor={id}> {label}</label>
          <span className="focus-bar"></span>
        </>
      ) : (
        <label style={{ display: 'none' }} htmlFor={id}>
          {label}
        </label>
      )}

      {errorMessage ? <p className="error-message">{errorMessage}</p> : null}
    </TextFieldStyles>
  );
});

TextField.defaultProps = {
  automationId: '',
  className: '',
  disabled: false,
  defaultValue: '',
  errorMessage: '',
  id: '',
  textFieldStyle: 'primary',
  label: '',
};

TextField.propTypes = {
  automationId: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  defaultValue: PropTypes.string,
  errorMessage: PropTypes.string,
  id: PropTypes.string,
  textFieldStyle: PropTypes.oneOf([
    '',
    'primary',
    'primary-background',
    'outlined',
    'compact',
  ]),
  label: PropTypes.string,
  name: PropTypes.string,
  IS_PASSWORD: PropTypes.bool,
  onChange: PropTypes.func,
  value: PropTypes.any,
};

export default TextField;

export const PasswordField = React.forwardRef(function PasswordField(
  { ...props },
  ref
) {
  return <TextField ref={ref} {...props} IS_PASSWORD={true} />;
});

PasswordField.defaultProps = {
  automationId: '',
  className: '',
  disabled: false,
  defaultValue: '',
  errorMessage: '',
  id: '',
  textFieldStyle: 'primary',
  label: '',
};
