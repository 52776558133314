import { pushGTMEvent } from 'pubweb-smokey/dist/utils/analytics';

// This will push a form action event and parameters related to the form action.
// It is wrapped in this function so any unused form action parameters will be cleared in the data layer.
//
// For example, suppose one form action sets a model number and another form action does not.
// We would like to clear the model number in the data layer for the form action event that
// does not use that value so it will not be unintentionally associated with that action.
export const pushGtmFormAction = ({
  form_action_type,
  form_name,
  model_number,
  hc_number,
  form_method,
  form_id,
  form_submit_text,
}) => {
  // push a form_action event
  pushGTMEvent('form_action', '', {
    form_action_type,
    form_name,
    model_number,
    hc_number,
    form_method,
    form_id,
    form_submit_text,
  });
};
