import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import Image from 'next/image';
import HomesCarouselStyles from './HomesCarousel.styled';
import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
} from 'pure-react-carousel';

import { desktop_breakpoint } from 'pubweb-smokey/dist/components/GridSystem/_vars_widths.js';
import useWindowResize from 'pubweb-smokey/dist/hooks/useWindowResize';

import HomeCardAspect from '@components/Shared/HomeCards/Standard/HomeCardAspect';
import { pushGTMEvent } from 'pubweb-smokey/dist/utils/analytics';
import 'pure-react-carousel/dist/react-carousel.es.css';

import NextBtn from '../next-btn.svg';
import PrevBtn from '../prev-btn.svg';
import { DXFavoritesContext } from '@contexts/DXFavoritesContext';
import { getSiteUrl } from '@utils/config';
import { getUrlTarget, getSlug, isUrlInternal } from '@utils/utils';
import {
  useHorizontalSwipe,
  useHorizontalSwipeGtm,
  useHorizontalDragGtm,
} from '@utils/hooks';

export default function HomesCarousel({
  homes,
  ctas,
  internallyLinked = true,
  isHomePage = true,
}) {
  const favoritesContext = useContext(DXFavoritesContext);

  const homeCard = (model) => {
    const cardLink = internallyLinked
      ? `/homes/${model.modelNumber}/`
      : getSiteUrl(`/homes/${model.modelNumber}/`);

    return (
      <HomeCardAspect
        onClick={(e) => {
          e.stopPropagation();
        }}
        key={`$featured-home-card-${model.modelNumber}`}
        homeObject={{ ...model, altTextDescription: model.modelDescription }}
        imageAspectRatio={{ width: 512, height: 365 }}
        imageQueryString={`?width=512&fm=webp`}
        images={model.images.slice(0, 5)}
        cardLink={cardLink}
        imgComponent={Image}
        useSlideshow={useIndicators}
        showIndicators={useIndicators}
        showArrows={false}
        usePriceTooltip={false}
        showPricing={true}
        lowPrice={model.lowPrice}
        isInStock={false}
        showFavoritingHeart={!isHomePage}
        isFavorited={
          !isHomePage &&
          !!favoritesContext.state.favoriteModels?.filter(
            (faveModels) => faveModels.modelNumber === model.modelNumber
          )[0]
        }
        handleFavoriteClick={
          !isHomePage
            ? (e) => {
                e.preventDefault();
                favoritesContext.actions.toggleFavoriteModel(model.modelNumber);
                pushGTMEvent('clickFavoritesHeart');
              }
            : null
        }
        dynamicHeight={false}
        showStockIndicator={false}
      />
    );
  };

  const ctaCard = (cta, i) => {
    let cardLink;

    if (isUrlInternal(cta.firstCtaButtonUrl)) {
      cardLink = getSlug(cta.firstCtaButtonUrl);
    } else {
      cardLink = cta.firstCtaButtonUrl;
    }

    return (
      <HomeCardAspect
        key={`featured-cta-card-${i}`}
        imageAspectRatio={{ width: 512, height: 365 }}
        imageQueryString={`?w=512&fm=webp`}
        homeObject={{
          modelDescription: (
            <>
              <div className="heading-text">{cta.ctaHeader}</div>
              {/* <div className="chevron"><img src={ChevronRightSvg} alt="" /></div> */}
            </>
          ),
          altTextDescription: cta.ctaHeader,
        }}
        images={[{ reference: cta?.ctaImage?.file?.url }]}
        imgComponent={Image}
        forceExternalLink={!isUrlInternal(cta.firstCtaButtonUrl)}
        cardLink={cardLink}
        cardLinkTarget={getUrlTarget(cta.firstCtaButtonUrl)}
        useSlideshow={false}
        showFavoritingHeart={false}
        showStockIndicator={false}
        showSpecs={false}
      />
    );
  };

  const buildCards = (homes, ctas) => {
    let cards = [];

    if (homes.length > 2) {
      // If three or more homes, just make an array of them.
      for (let i = 0; i < homes.length; i++) {
        cards.push(homeCard(homes[i]));
      }
    } else {
      // If less than three homes, add one or two CTAs (if available) to make a total of three items.
      // For one home: [home, cta, cta]. For two homes: [home, home, cta].
      // If mobile, don't show the CTAs.
      switch (homes.length) {
        case 2:
          cards.push(homeCard(homes[0]));
          cards.push(homeCard(homes[1]));
          if (!isMobile) {
            0 < ctas?.length ? cards.push(ctaCard(ctas[0], 0)) : undefined;
          }
          break;
        case 1:
          cards.push(homeCard(homes[0]));
          if (!isMobile) {
            0 < ctas?.length ? cards.push(ctaCard(ctas[0], 0)) : undefined;
            1 < ctas?.length
              ? cards.push(ctaCard(ctas[1], 1))
              : 0 < ctas?.length
                ? cards.push(ctaCard(ctas[0], 0))
                : undefined;
          }
          break;

        default:
          break;
      }
    }

    return cards;
  };

  const [visibleSlides, setVisibleSlides] = useState(1.05);
  const [step, setStep] = useState(1);
  const [dragStep, setDragStep] = useState(1);
  const [useIndicators, setUseIndicators] = useState(false);
  const [isMobile, setIsMobile] = useState(true);

  const [cards, setCards] = useState(buildCards(homes, ctas));

  useWindowResize(() => {
    if (window.innerWidth >= 960) {
      setVisibleSlides(3);
      setStep(3);
      setDragStep(3);
      setUseIndicators(true);
      setIsMobile(false);
    } else if (window.innerWidth >= desktop_breakpoint) {
      setVisibleSlides(3);
      setStep(3);
      setDragStep(3);
      setUseIndicators(true);
      setIsMobile(false);
    } else if (window.innerWidth >= 375) {
      setVisibleSlides(1.15);
      setStep(1);
      setDragStep(1);
      setUseIndicators(false);
      setIsMobile(true);
    } else if (window.innerWidth >= 345) {
      setVisibleSlides(1.1);
      setStep(1);
      setDragStep(1);
      setUseIndicators(false);
      setIsMobile(true);
    } else {
      setVisibleSlides(1.05);
      setStep(1);
      setDragStep(1);
      setUseIndicators(false);
      setIsMobile(true);
    }
  }, [345, 375, 960, desktop_breakpoint]);

  // The cards are built differently depending on useInidcators and isMobile values.
  useEffect(() => {
    setCards(buildCards(homes, ctas));
  }, [favoritesContext, useIndicators, isMobile]);

  // On mobile (touch device), the carousel sometimes moves up/down vertically when swiping.
  // Added this code to detect horizontal swipe and prevent the up/down scrolling.
  useHorizontalSwipe('homes-carousel');

  // Added this code to detect horizontal swipe to fire a GTM event.
  useHorizontalSwipeGtm('homes-carousel', 'gtm-chcom-homes-carousel-swipe');

  // Added this code to detect horizontal mouse click and drag to fire a GTM event
  useHorizontalDragGtm(
    'homes-carousel-slider',
    'gtm-chcom-homes-carousel-drag'
  );

  // If mobile and only one home to show, don't use the carousel.
  return (
    <HomesCarouselStyles>
      {isMobile && cards?.length === 1 ? (
        <div className="mobile-single-card">{cards[0]}</div>
      ) : (
        <CarouselProvider
          id="homes-carousel"
          isIntrinsicHeight={true}
          totalSlides={cards.length}
          visibleSlides={visibleSlides}
          step={step}
          dragStep={dragStep}
          infinite={true}
        >
          <Slider id="homes-carousel-slider">
            {cards.map((c, index) => {
              return <Slide key={index}>{c}</Slide>;
            })}
          </Slider>

          {cards.length > 3 && (
            <ButtonBack
              className="direction-button direction-left"
              onClick={() =>
                pushGTMEvent('gtm-chcom-homes-carousel-button-left')
              }
            >
              <PrevBtn />
            </ButtonBack>
          )}
          {cards.length > 3 && (
            <ButtonNext
              className="direction-button direction-right"
              onClick={() =>
                pushGTMEvent('gtm-chcom-homes-carousel-button-right')
              }
            >
              <NextBtn />
            </ButtonNext>
          )}
        </CarouselProvider>
      )}
    </HomesCarouselStyles>
  );
}

HomesCarousel.propTypes = {
  ctas: PropTypes.array,
  homes: PropTypes.array,
  internallyLinked: PropTypes.bool,
  isHomePage: PropTypes.bool,
};
